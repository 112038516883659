export const LocaleSwitchingMixin = {
  watch: {
    lang (lang) {
      this._setLocale(lang)
    },
  },
  created () {
    const lang = this.lang || this.$route.query?.lang || navigator.language
    this._setLocale(lang)
  },
  methods: {
    _setLocale (lang) {
      if (!lang) return
      this.$i18n.locale = lang
      document.documentElement.setAttribute('lang', lang)
      let fontLink = document.getElementById('language')
      if (!fontLink) {
        fontLink = document.createElement('link')
        fontLink.setAttribute('id', 'font')
        fontLink.setAttribute('rel', 'stylesheet')
      }
      fontLink.setAttribute('href', this.$t('font'))
      document.head.appendChild(fontLink)
    }
  }
}
